/* istanbul ignore file */
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import ErrorPage from './error-page';
import './index.scss';
import { DependencyContainer } from './plumbing/DependencyContainer';
import { ServiceFactory } from './plumbing/ServiceFactory';
import { SeatUpgradeSummary } from './routes/seat-upgrade/seat-upgrade-summary';
import { DisplayService, IDisplayService } from './services/seat-upgrade/display-service';
import { SeatUpgradePayment } from './routes/seat-upgrade/seat-upgrade-payment';
import { IPaymentService, PaymentService } from './services/seat-upgrade/payment-service';
import { ConfirmationService, IConfirmationService } from './services/seat-upgrade/confirmation-service';
import { SeatUpgradeConfirmation } from './routes/seat-upgrade/seat-upgrade-confirmation';
import { SeatUpgradeTimeout } from './routes/seat-upgrade/seat-upgrade-timeout';

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <div>Nothing to see here</div>,
      errorElement: <ErrorPage />
    },
    {
      path: 'seat-upgrade-summary/:tx',
      element: <SeatUpgradeSummary />
    },
    {
      path: 'seat-upgrade-payment/:tx',
      element: <SeatUpgradePayment />
    },
    {
      path: 'seat-upgrade-confirmation/:tx',
      element: <SeatUpgradeConfirmation />
    },
    {
      path: 'seat-upgrade-timeout',
      element: <SeatUpgradeTimeout />
    }
  ]
);

let displayService: IDisplayService | undefined = undefined;
let paymentService: IPaymentService | undefined = undefined;
let confirmationService: IConfirmationService | undefined = undefined;

const serviceFactory: ServiceFactory = {
  GetDisplayService: function (): IDisplayService {
    if(!displayService) {
      displayService = new DisplayService();
    }

    return displayService;
  },
  GetPaymentService: function (): IPaymentService{
    if(!paymentService) {
      paymentService = new PaymentService();
    }
    return paymentService;
  },
  GetConfirmationService: function (): IConfirmationService {
    if(!confirmationService) {
      confirmationService = new ConfirmationService();
    }
    return confirmationService;
  }
};

ReactDOM.createRoot(document.getElementById('root')!).render(
  <DependencyContainer serviceFactory={serviceFactory}>
    <RouterProvider router={router} />
  </DependencyContainer>
);
